<template>
  <div class="view">
    <header>
      <nav :class="showMenu ? 'open' : ''">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/game">Game</router-link></li>
        </ul>
      </nav>
      <div class="menu-button">
        <button @click="toggleMenu" v-html="showMenu ? closeSvg : menuSvg"></button>
      </div>
    </header>
    
    <router-view></router-view>
  </div>
  
    
</template>
<script setup>

// after each route change, close the menu
import { watch } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
watch(router.currentRoute, () => {
  showMenu.value = false;
});

import { ref } from 'vue';

const showMenu = ref(false);

const closeSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>`;

const menuSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>`;

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

</script>

<style>

/* override bootstrap colors */

:root {
  --bs-primary: #007bff;
  --bs-secondary: #6c757d;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
}

body {
    background-image: linear-gradient(0deg, #00000080, #00000080), url('@/assets/background.jpg');
    background-repeat: no-repeat;
    background-size: 100%, cover;
    background-position: center;
    min-height: calc(100svh - 3rem);
  }

header nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: -1000px;
    max-width: 300px;
    width: 100%;
    background: white;
    transition: all 0.5s ease-in-out;
}

header nav.open {
    left: 0;
}

header .menu-button {
    position: fixed;
    top: 12px;
    right: 12px;
    z-index: 1001;
    
}

  header .menu-button button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: white;
      line-height: 0;
  }

  header .menu-button button svg {
    width: 100%;
    height: 100%;
}

header nav ul {
    padding: 20px;
    margin: 0;
    list-style: none;
}

header nav ul li {
    /* border-bottom: 1px solid black; */
    text-align: center;
    margin: 12px 0;
}

header nav ul li a {
    width: 100%;
    height: 100%;
    display: block;
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 1px 5px #00000040;
}

nav a {
    text-decoration: none;
    color: inherit;
}

header nav ul li a:hover {box-shadow: 3px 3px 5px #00000040;background: #f0f0f0;}

@media screen and (max-width: 768px) {
  body {
    min-height: calc(100svh - 0.5rem);
  }
}

</style>
