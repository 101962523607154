import { createRouter, createWebHistory  } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/PageHome.vue')
    },
    {
        path: '/game',
        name: 'Game',
        component: () => import('@/views/PageGame.vue')
    }
];

// whenever a route changes, hide the menu


const router = createRouter({
  history: createWebHistory(),
  routes,
})



export default router